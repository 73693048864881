<!-- components/Login.vue -->
<template>
  <ion-page>
    <ion-content>
      <div class="margin-web">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <img class="media" src="/resources/Logo_HG.png" alt="Happygift logo" />
        </ion-row>
        <h1 class="title ion-text-center font-web-20">Ingresa como HappyGifter</h1>
        <ion-row class="pt-5">
          <ion-col style="display: flex; justify-content: space-around; padding-bottom: 0px;">
              <ion-button mode="ios" fill="clear" class="btn btn-dates btn--borderRadiusXL customDates" @click="signInWithGoogle">
                  <img v-if="!session.loginWithSocial.loading" src="../../assets/icons/google-icon.png" alt="google-icon"/>
                  <ion-spinner v-if="session.loginWithSocial.loading" name="circles"></ion-spinner>
              </ion-button>
              <ion-button v-if="platform !== 'android' && Capacitor.isNativePlatform()" mode="ios" fill="clear" class="btn btn-dates btn--borderRadiusXL customDates" @click="singWithapple()">
                  <ion-icon v-if="!session.loginWithSocial.loading" class="icon-apple" style="font-size: 35px;" slot="icon-only" color="dark"></ion-icon>
                  <ion-spinner v-if="session.loginWithSocial.loading" name="circles"></ion-spinner>
              </ion-button>
            </ion-col>
            <ion-col size="12">
              <div class="line-container">
                <div class="line"></div>
                <div class="circle">O</div>
                <div class="line"></div>
              </div>
            </ion-col>
          <ion-col size="12">
            <ion-list class="ion-no-padding">
              <ion-item :class="{'item-field': emailMatch == true, 'wrong-input': !emailMatch}">
                <ion-input v-model="email" inputmode="text" type="email" label-placement="floating" @input="validateEmail"  style="font-size: 16px !important;">
                  <div slot="label" class="font-15" style="color: #b7aaaa;">Email</div>
                </ion-input>
              </ion-item>
              <ion-text v-if="!emailMatch && email.length > 0" class="font-12 text-error" color="danger">Email inválido</ion-text>
              <ion-item :class="{'item-field': password.length > 7 || password.length == 0, 'wrong-input': password.length < 8 && password.length > 0}">
                <ion-input v-model="password" :type="passwordFieldType" label-placement="floating" style="font-size: 16px !important;">
                  <div slot="label" class="font-15" style="color: #b7aaaa;">Contraseña</div>
                </ion-input>
                <i class="icon" :class="{'icon-eye': passwordFieldType == 'text', 'icon-eyeblock': passwordFieldType == 'password'}" slot="end" @click="switchVisibility"></i>
              </ion-item>
              <ion-text v-if="password.length < 8 && password.length > 0" class="font-12 text-error" color="danger">La contraseña debe tener mínimo 8 caracteres</ion-text>
            </ion-list>
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-justify-content-center">
                <ion-col size="9">
                <!--   <ErrorMessage :message="session.login.error" /> -->
                  <ion-button v-model="submitButton" class="font-16 login-btn btn" shape="round" expand="block" mode="ios" @click="submitEmail" :disabled="isButtonDisabled">
                    <ion-label v-if="!session.login.loading">Iniciar sesión</ion-label> 
                    <ion-spinner v-if="session.login.loading" name="circles"></ion-spinner>
                  </ion-button>
                </ion-col>
                <ion-col size="12">
                  <router-link to="forgot" style="text-decoration: none;">
                    <p class="font-14 ion-text-center ion-no-margin ion-padding font-web-16 cursor" style="color: black;">
                      ¿Olvidaste la contraseña?
                    </p>
                  </router-link>
                </ion-col>

                <ion-col size="12">
                  <div class="ion-padding font-14 ion-text-center" lines="none">
                    <ion-text class="text-link font-14 font-web-16">¿Todavía no tienes cuenta?</ion-text>
                    <br>
                    <router-link to="signup" class="font-bold font-16" color="primary" style="text-decoration: none;">Regístrate aquí</router-link>
                    <br>
                    <br>
                    <ion-text class="font-16 cursor" color="dark" style="--ion-color-primary: black; text-decoration: underline" @click="continueAsGuest">Continuar como invitado</ion-text>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
  import { ref, computed, inject, watch } from 'vue';
  import { IonPage, IonContent, IonButton, IonGrid, IonRow, IonCol, IonList, IonItem, IonInput, IonText, toastController, IonSpinner, IonLabel, onIonViewDidEnter, IonIcon} from '@ionic/vue';
  import { alertCircleOutline } from 'ionicons/icons'
  import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
  import { Capacitor } from '@capacitor/core';
  import router from '@/router';

  const email = ref('');
  const password = ref('');
  const passwordFieldType = ref<'text' | 'password'>('password');
  const emailMatch = ref<any>(true);
  const submitButton = ref(false);
  const platform = ref(null)

  const isButtonDisabled = computed(() => {
    return !email.value || !password.value || !emailMatch.value || password.value.length < 8;
  });

  const session: any = inject("$session");
  // const user: any = inject("$user");

  onIonViewDidEnter(async () => {
    platform.value = Capacitor.getPlatform()
    // console.log('estas en: ', platform.value)
  })

  watch(() => session.login.error, (error) => {
    if (error) { 
      errorToast(session.login.error.messages[0])
    }
  })

  watch(() => session.loginWithSocial.error, (error) => {
    if (error) { 
      errorToast(session.loginWithSocial.error.messages[0])
    }
  })

  const submitEmail = () => {
    session.login.submit({
      email: email.value,
      password: password.value,
    })
  }

  const validateEmail = () => {
    if(email.value.length > 0){
      const isValid = email.value.match(
          /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        );
        emailMatch.value = isValid !== null;
    } else {
      emailMatch.value = true;
    }
  }

  const switchVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
  }

  const errorToast = async (message: string) => {
    const toast = await toastController.create({
      message,
      duration: 3500,
      position: 'top',
      icon: alertCircleOutline,
      mode: 'ios'
    });

    await toast.present();
  }

const singWithapple = async () => {
  const result = await FirebaseAuthentication.signInWithApple();
  // console.log("user -> ", result);
  session.loginWithSocial.submit({provider: 'apple', user: result.user, token: result.credential.idToken})
  return result.user;
};

const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle();
  // console.log("user -> ", result.user, result);
  session.loginWithSocial.submit({provider: 'google',user: result.user, token: result.credential.accessToken})
  return result.user;
};

const continueAsGuest = () => {
  router.replace({ name: 'home' });
};

//   import { createResource } from 'frappe-ui'

//   let showEmailLogin = ref(false)

//   let authProviders = createResource({
//   url: 'happygift.auth.oauth_providers',
//   auto: true,
//   onSuccess(data) {
//     showEmailLogin.value = data.length === 0
//   },
// })
// authProviders.fetch()
</script>

<style scoped>
  .media {
    display: block;
    height: auto;
    margin: 30px auto 10px;
    max-width: 150px;
    padding: 0;
    width: 100%;
  }

  .text-error {
    --background: transparent;
    --highlight-height: 0;
    --padding: 0.5rem;
    width: auto;
    padding: 0;
    margin: 4px 15px 10px 30px;
    display: block;
  }

  .pt-5 {
    padding-top: 10px;
  }

  .title {
    font-size: 16px;
    font-weight: lighter;
    margin: 0 0 0 15px;
    padding: 5px 25px 2px;
  }

  .font-10{
    font-size: 10px
  }

  .font-12{
    font-size: 12px
  }

  .font-13{
    font-size: 13px
  }

  .font-14{
    font-size: 14px
  }

  .font-15{
    font-size: 15px
  }

  .font-16{
    font-size: 16px
  }

  .font-18{
    font-size: 18px
  }

  .font-20{
    font-size: 20px
  }

  .font-bold{
    font-weight: bold;
  }

  .text-link {
    margin-right: 0.8rem;
  }

  .item-field {
    --background: transparent !important;
    --border-color: transparent !important;
    --highlight-height: 0 !important;
    --padding: 0.5rem !important;
    border: solid 1px #b7aaaa !important;
    border-radius: 10px !important;
    margin: 15px 15px 20px 15px !important;
    width: auto !important;
    padding: 0 !important;
    /* max-height: 5.8rem; */
  }

  .wrong-input{
    --background: transparent !important;
    --highlight-height: 0 !important;
    --padding: 0.5rem !important;
    width: auto !important;
    padding: 0 !important;
    --border-color: transparent !important;
    border: solid 1px #b7aaaa !important;
    border-radius: 10px !important;
    margin: 15px 15px 0 15px !important;
  }

  .icon {
    left: 0;
    /* position: absolute; */
    margin-right: 5px;
  }

/*   .social-btn{
    border-radius: 50rem;
  } */

  .login-btn{
    --background-activated: #cf4637;
    --background-focused: #cf4637;
    border-radius: 64px;
    box-shadow: 0 0.2rem 0.5rem #434141 ;
  }

  .btn{
    margin: 10px 15px;
  }

  .icon-change {
    transform: translateY(1.8rem);
  }

  .customDates {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15);
  }

  .btn-dates {
      border-radius: 50% !important; /* Hace el botón completamente redondo */
      height: 65px; /* Ajusta el tamaño del botón según sea necesario */
      width: 65px; /* Ajusta el tamaño del botón según sea necesario */
      padding: 0; /* Elimina cualquier relleno dentro del botón */
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .line-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .line {
    height: 1px;
    background-color: lightgray;
    flex-grow: 1;
  }

  .circle {
    font-size: 16px; /* Ajusta el tamaño según tus necesidades */
    font-weight: bold;
    margin: 0 10px;
    color: black;
    white-space: nowrap;
  }
  .cursor{
    cursor: pointer !important;
  }
  @media (min-width: 900px) {
    .margin-web{
      margin-left: 25%;
      margin-right: 25%;
    }
    .font-web-16{
      font-size: 16px;
    }
    .font-web-18{
      font-size: 18px;
    }
    .font-web-20{
      font-size: 20px;
    }
  }
</style>