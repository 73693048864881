import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import TabsPage from '../views/admin/TabsPage.vue';
import Login from '../views/public/LoginPage.vue';
import Signup from '../views/public/SignupPage.vue';
import Forgot from '../views/public/ForgotPage.vue';
import UpdatePassword from '../views/public/resetPasswordPage.vue';

// meta: { requiresAuth: true } 
// meta: { isPublic: true }
// meta: { isUnique: true }

// no van
// path: 'profile/coupons',
// path: 'home/coupons',
// path: '/public/welcome'

const routes: Array<RouteRecordRaw> = [
  //Privadas
  //ruta sola lleva a admin. admin lleva a admin/home
  {
    path: '/',
    redirect: '/admin/'
  },
  {
    path: '/admin/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/admin/home'
      },
      {
        path: '/admin/home',
        component: () => import('@/views/admin/HomePage.vue'),
        name: 'home'
      },
      {
        path: 'contacts',
        component: () => import('@/views/admin/ContactsPage.vue'),
        name: 'contacts'
      },
      {
        path: 'wishes',
        component: () => import('@/views/admin/WishesPage.vue'),
        name: 'wishes'
      },
      {
        path: 'celebrations',
        component: () => import('@/views/admin/CelebrationsPage.vue'),
        name: 'celebrations'
      },{
        path: 'EventDashboard/:id',
        component: () => import('@/views/admin/EventDashboardPage.vue'),
        name: 'EventDashboard'
      },
      {
        path: 'profile',
        component: () => import('@/views/admin/ProfilePage.vue'),
        name: 'profile'
      },
      // {
      //   path: 'profile/coupons',
      //   component: () => import('@/views/admin/CouponsPage.vue'),
      // },
      // {
      //   path: 'home/coupons',
      //   component: () => import('@/views/admin/CouponsPage.vue'),
      //   name: 'coupons'
      // },
      {
        path: 'catalogue',
        component: () => import('@/views/admin/CataloguePage.vue'),
        name: 'catalogue'
      },
      {
        path: 'cart',
        component: () => import('@/views/admin/CartPage.vue'),
        name: 'cart'
      },
      {
        path: 'item/:id/:color?/:size?',
        component: () => import('@/views/admin/ItemPage.vue'),
        name: 'item',
        props: true
      },
      {
        path: 'wishContacts/:id',
        component: () => import('@/views/admin/wishContactsPage.vue'),
        name: 'wishContacts'
      },
      {
        path: '/admin/wishList/:id/:name/:is',
        component: () => import('@/views/admin/wishListPage.vue'),
        name: 'wishList'
      }
    ]
  },
  {
    path: '/admin/cart/checkout',
    component: () => import('@/views/admin/CheckoutPage.vue'),
    name: 'checkout'
  },
  {
    path: '/admin/social_login_register',
    component: () => import('@/views/admin/SocialRegisterPage.vue'),
    name: 'social-register'
  },

  //Públicas
  {
    path: '/public/',
    component: TabsPage,
    children:[
      {
        path: '',
        redirect: '/public/home'
      },
      {
        path: '/public/home',
        component: () => import('@/views/public/HomePublicPage.vue'),
        name: 'homePublic',
        meta: { isPublic: true }
      },
      {
        path: '/public/catalogue',
        component: () => import('@/views/public/CataloguePublicPage.vue'),
        name: 'cataloguePublic',
        meta: { isPublic: true }
      },
      {
        path: '/public/item/:id/:color?/:size?',
        component: () => import('@/views/public/ItemPublicPage.vue'),
        name: 'itemPublic',
        meta: { isPublic: true }
      },
      {
        path: '/public/cart',
        component: () => import('@/views/public/CartPublicPage.vue'),
        name: 'cartPublic',
        meta: { isPublic: true }
      },
      {
        path: '/public/wishList/:id/:name/:is',
        component: () => import('@/views/public/wishListPage.vue'),
        name: 'wishListPublic',
        meta: { isPublic: true }
      },
      {
        path: '/public/profiles',
        component: () => import('@/views/public/PublicProfilesPage.vue'),
        name: 'profiles',
        meta: { isPublic: true, isUnique: true }
      }
    ]
  },
  {
    path: '/public/cart/checkout',
    component: () => import('@/views/public/CheckoutNotUserPage.vue'),
    name: 'checkoutNotUser',
    meta: { isPublic: true }
  },
  {
    path: '/public/',
    redirect: '/public/login',
    meta: { isPublic: true }
  },
  {
    path: '/public/login',
    component: Login,
    name: 'login',
    meta: { isPublic: true }
  },
  {
    path: '/public/signup',
    component: Signup,
    name: 'signup',
    meta: { isPublic: true }
  },
  {
    path: '/public/forgot',
    component: Forgot,
    name: 'forgot',
    meta: { isPublic: true }
  },
  {
    path: '/update-password',
    component: UpdatePassword,
    name: 'update-password',
    meta: { isPublic: true }
  },
  {
    path: '/public/order-status',
    component: () => import('@/views/public/OrderStatusPage.vue'),
    name: 'orderStatus',
    meta: { isPublic: true }
  },
  {
    path: '/public/order-status-fail',
    component: () => import('@/views/public/OrderStatusFailPage.vue'),
    name: 'orderStatusFail',
    meta: { isPublic: true }
  }
  // {
  //   path: '/public/welcome',
  //   component: () => import('@/views/public/WelcomePage.vue'),
  //   name: 'welcome'
  // },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes
})

// depp-links
App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const slug = decodeURIComponent(event.url.split('.com').pop() || '');

  if (slug) {
    const [path, queryString] = slug.split('?');
    const query = Object.fromEntries(new URLSearchParams(queryString || ''));

    router.push({
      path,
      query,
    });
    
    console.log(`Navigating to path: ${path} with query:`, query);
  }
});


export default router
