<template>
    <ion-page>
        <ion-header mode="ios" class="header" style="margin: 15px 0px;">
            <ion-toolbar class="header-toolbar header-toolbar-searcher">
                <h4 class="header-title">Contraseña</h4>
                <ion-buttons class="header-btn header-btn-back" slot="start">
                    <ion-back-button mode="ios" text="" color="dark"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <h1 class="title ion-text-center">Restablecer la contraseña</h1>
            <ion-row>
                <ion-col>
                    <ion-list class="ion-no-padding">
                        <ion-item :class="{'item-field': password.length > 7 || password.length == 0, 'wrong-input': password.length < 8 && password.length > 0}">
                            <ion-input v-model="password" :type="passwordFieldType" label-placement="floating">
                            <div slot="label" class="font-15" style="color: #b7aaaa;">Nueva contraseña</div>
                            </ion-input>
                            <i class="icon" :class="{'icon-eye': passwordFieldType == 'text', 'icon-eyeblock': passwordFieldType == 'password'}" slot="end" @click="switchVisibilityPass"></i>
                        </ion-item>
                        <ion-text v-if="password.length < 8 && password.length > 0" class="font-12 text-error" color="danger">La contraseña debe tener mínimo 8 caracteres</ion-text>
                        <ion-item :class="{'item-field': passwordsMatch || confirmPassword.length == 0, 'wrong-input': !passwordsMatch && confirmPassword.length > 0}">
                        <ion-input v-model="confirmPassword" :type="passwordFieldTypeConfirm" label-placement="floating" @input="handleConfirmPassword">
                            <div slot="label" class="font-15" style="color: #b7aaaa;">Confirmar contraseña</div>
                        </ion-input>
                        <i class="icon" :class="{'icon-eye': passwordFieldTypeConfirm == 'text', 'icon-eyeblock': passwordFieldTypeConfirm == 'password'}" slot="end" @click="switchVisibilityConfirm"></i>
                        </ion-item>
                        <ion-text v-if="!passwordsMatch && confirmPassword.length > 0" class="font-12 text-error" color="danger">Las contraseñas no coinciden</ion-text>
                    </ion-list>
                    <ion-col size="9">
                        <ion-button class="font-16 login-btn btn" shape="round" expand="block" mode="ios" @click="requestPasswordReset" :disabled="isButtonDisabled">
                            <ion-label>Confirmar</ion-label> 
                            <!-- <ion-spinner v-if="session.login.loading" name="circles"></ion-spinner> -->
                        </ion-button>
                    </ion-col>
                    <div v-if="timeOut" style="text-align: center;">
                        <p>El enlace para restablecer la contraseña ha caducado.</p>
                        <router-link to="forgot" class="font-bold" color="primary" style="text-decoration: none;">solicitar nuevo enlace</router-link>
                    </div>
                    <div v-if="error" style="text-align: center;">
                        <p>{{errorMessage}}</p>
                    </div>
                </ion-col>
            </ion-row>
        </ion-content>
    </ion-page>
</template>
<script setup lang="ts">
import { updatePassword } from '@/data/session';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonContent, IonRow, IonCol, IonList, IonItem, IonInput, IonText, IonButton, IonLabel } from '@ionic/vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from "@/router"

const route = useRoute();

const password = ref('');
const passwordFieldType = ref<'text' | 'password'>('password');
const passwordFieldTypeConfirm = ref<'text' | 'password'>('password');
const passwordsMatch = ref(true);
const confirmPassword = ref('');
const error = ref(false)
const timeOut = ref(false);
const errorMessage = ref();

const switchVisibilityPass = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
};

const switchVisibilityConfirm = () => {
    passwordFieldTypeConfirm.value = passwordFieldTypeConfirm.value === 'password' ? 'text' : 'password';
};

const isButtonDisabled = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    timeOut.value = false;
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    error.value = null;
    return !password.value || !passwordsMatch.value || password.value.length < 8;
  });

const handleConfirmPassword = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    passwordsMatch.value = confirmPassword.value === password.value;
    return passwordsMatch.value;
});

// const onSubmit = () => {
//     console.log("click", password.value)
//     console.log("key", route.query.key)
// }
const key_query = route.query['key']


async function requestPasswordReset() {
  await updatePassword.updateEmail.submit({
    password_: password.value,
    key_ : key_query , 
  }).
  then((data)=>{
    // succesfull.value= true
    // error.value = false
    if(data === '/app'){
        router.push({name:'home'})
    }
    
 })
  .catch((e)=>{
    console.log("error:", e.messages)
    if (e.messages[0].includes("Invalid Password: Names and surnames by themselves are easy to guess")){
    // error.value = true
    }
    if (e.messages[0].includes("The reset password link has been expired")){
    // error.value = true
        timeOut.value = true;
    }
    if(e.messages[0].includes('The reset password link has either been used before or is invalid')){
        error.value = true
        errorMessage.value = 'El enlace para restablecer la contraseña se ha utilizado antes o no es válido'
    }
    else{
        error.value = true
        errorMessage.value = e.messages[0]
    }
   console.log('error en el reseteo de contraseña' )
  })
}
</script>