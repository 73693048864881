type FacebookPixelEvent = 
  | 'PageView'
  | 'ViewContent'
  | 'AddToCart'
  | 'InitiateCheckout'
  | 'AddToWishlist'
  | 'Purchase'
  | 'CompleteRegistration'
  | 'AddPaymentInfo'
  | 'Search';

interface EventParams {
  content_ids?: string[]; // IDs de los productos involucrados
  content_type?:string; // Puede ser product o product_group
  content_name?: string;  // Nombre del producto o categoría
  content_category?: string; // Categoría de los productos
  value?: number;         // Valor asociado al evento
  currency?: string;      // Moneda
  num_items?: number;     // Número de artículos
  search_string?: string;
}

export function useFacebookPixel() {
  /**
   * Rastrear un evento personalizado con el Pixel de Facebook.
   * @param eventName - Nombre del evento (ej. 'PageView', 'AddToCart').
   * @param params - Parámetros adicionales para el evento.
   */
  function trackEvent(eventName: FacebookPixelEvent, params: EventParams = {}): void {
    if (typeof window.fbq === 'function') {
      window.fbq('track', eventName, params);
    } else {
      console.error(`Facebook Pixel no está disponible para el evento ${eventName}`);
    }
  }

  return { trackEvent };
}
