<template>
    <ion-page>
        <ion-header mode="ios" class="header" style="margin: 15px 0px;">
            <ion-toolbar class="header-toolbar header-toolbar-searcher">
                <h4 class="header-title">Seguridad</h4>
                <ion-buttons class="btn-header" slot="start">
                    <router-link to="login">
                        <ion-button color="dark" icon="null" class="icon icon-arrowbackblack" style="margin-top: 15px;"></ion-button>
                    </router-link>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div class=margin-web>
            <div style="text-align: center; margin: 10px 15px 0 15px;">
                <h3 style="font-family: NunitoBold;">Restablecer contraseña</h3>
                <p>Por favor introduce tu email. Te ayudaremos a restablecer tu contraseña</p>
            </div>
            <ion-row>
                <ion-col>
                    <ion-list class="ion-no-padding">
                        <ion-item :class="{'item-field': emailMatch == true, 'wrong-input': !emailMatch}">
                            <ion-input v-model="email" inputmode="text" type="email" label-placement="floating" @input="validateEmail">
                            <div slot="label" class="font-15" style="color: #b7aaaa;">Email</div>
                            </ion-input>
                        </ion-item>
                        <ion-text v-if="!emailMatch && email.length > 0" class="font-12 text-error" color="danger">Email inválido</ion-text>
                    </ion-list>
                    <ion-col size="9">
                        <ion-button class="font-16 login-btn btn" shape="round" expand="block" mode="ios" @click="requestPasswordReset" :disabled="isButtonDisabled">
                            <ion-label v-if="!loading">Enviar instrucciones</ion-label> 
                            <ion-spinner v-if="loading" name="circles"></ion-spinner>
                        </ion-button>
                    </ion-col>
                    <div v-if="error" style="text-align: center; margin: 0 25px;">
                        <p>{{errorMessage}}</p>
                        <p>{{errorMessageTwo}}</p>
                        <span>si lo prefieres: <router-link v-if="notFound" to="signup" class="font-bold" color="primary" style="text-decoration: none;">Regístrate aquí</router-link></span>
                    </div>
                    <div v-if="isSendedEmail && !error" style="text-align: center; margin: 0 25px;">
                        <p>Te enviamos un correo electrónico con el enlace para restablecer tu contraseña, esto puede tomar algunos minutos.</p>
                        <p>Podría llegar a la carpeta de spam. Recuerda revisarla.</p>
                    </div>
                </ion-col>
            </ion-row>
        </div>
        </ion-content>
    </ion-page>
</template>
<script setup lang="ts">
import { reset } from '@/data/session';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonContent, IonItem, IonInput, IonList, IonCol, IonButton, IonRow, IonLabel, IonText, IonSpinner } from '@ionic/vue';
import { computed, ref } from 'vue';

const email = ref();
const isSendedEmail = ref(false);
const error = ref(false);
const errorMessage = ref('');
const errorMessageTwo = ref('');
const notFound = ref(false);
const emailMatch = ref<any>(true);
const loading =ref(false)

async function requestPasswordReset() {
    loading.value = true
  await reset.resetEmail.submit({
    email: email.value,
  }).
  then((data)=>
    loading.value = false
  )
  .catch((e)=>{
    console.log("error:", e.messages)
    loading.value = false
    if (e.messages[0] == "not found"){
        error.value = true;
        notFound.value = true;
        errorMessage.value = "!Ups¡ No tenemos registrado este email.";
        errorMessageTwo.value = "Asegurate que este bien escrito y que sea el email que usaste para registrarte en nuestra app.";
    }
    if (e.messages[0] == "You hit the rate limit because of too many requests. Please try after sometime."){
        console.log("superaste el limite de intentos, vuelve a intentar mas tarde")
        error.value = true;
        errorMessage.value = "superaste el limite de intentos, vuelve a intentar mas tarde"
    }
  })
  isSendedEmail.value = true
}

const isButtonDisabled = computed(() => {
    return !email.value || !emailMatch.value ;
});

const validateEmail = () => {
    isSendedEmail.value = false
    error.value = false;
    console.log(13, isSendedEmail.value)
    error.value = false;
    if(email.value.length > 0){
      const isValid = email.value.match(
          /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        );
        emailMatch.value = isValid !== null;
    } else {
      emailMatch.value = true;
    }
}

</script>
<style scoped>
* {
    font-family: 'Nunito';
}
.header {
    background-color: #fff !important;
    transition: all 100ms linear;
    z-index: 1;
}
.header-toolbar {
    --background: white !important;
    align-items: center;
    display: flex;
    padding-top: var(--ion-safe-area-top);
    position: relative;
    transition: all 250ms ease;
    --border-width: 0 0 0 !important;
}
.header-btn {
    --overflow: white;

    position: absolute;
    right: 0;
}

.header-btn-back {
    height: 100%;
    left: 0;
    margin-left: 15px;
    top: 0;
}
.header-title{
    font-size: 16px;
    font-weight: lighter;
    margin: 0;
    margin-left: 15px;
    padding: 5px 25px;
}
.btn-header {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
}
@media (min-width: 900px) {
    .margin-web{
      margin-left: 25%;
      margin-right: 25%;
    }
  }
</style>