import "@capacitor-community/firebase-analytics";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isPlatform } from '@ionic/vue';

if(isPlatform('desktop') || isPlatform('mobileweb')){
  const firebaseConfig = {
    apiKey: "AIzaSyCH4Nsznk8s_IlxnjcKjNJV1KNDMv3xiHg",
    authDomain: "auth.happygiftapp.com",
    databaseURL: "https://happygift-cb045.firebaseio.com",
    projectId: "happygift-cb045",
    storageBucket: "happygift-cb045.appspot.com",
    messagingSenderId: "391482925150",
    appId: "1:391482925150:web:58b4816e74018286b3d4cf",
    measurementId: "G-3ND6M4N7RC"
  };
  FirebaseAnalytics.initializeFirebase(firebaseConfig);
}

class AnalyticsService {
  constructor(){
    this.setCollectionEnabled()
  }
  async setUserId(user) {
    FirebaseAnalytics.setUserId({
      userId: user,
    });
    this.setPropertyUser()
  }

  async setPropertyUser() {
    FirebaseAnalytics.setUserProperty({
      name: "framework",
      value: "vue"
    });
  }

  async pageView(url, name) {
    FirebaseAnalytics.logEvent({
      name: "page_view",
      params: {
        page_location: url,
        page_title: name
      },
    });
  }

  setCollectionEnabled() {
    FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  }

  async setScreenName(url) {
    FirebaseAnalytics.setScreenName({
      screenName: url
    })
  }

  async viewItem(item) {
    FirebaseAnalytics.logEvent({
      name: 'view_item',
      params: {
        currency: "CLP",
        value: item.price_list_rate,
        items: [{
          item_id: item.item_code,
          item_name: item.item_name,
          item_brand: item.brand,
          coupon: item.discount,
          item_category: item.item_group
        }]
      }
    })
  }

  async search(search_input){
    FirebaseAnalytics.logEvent({
      name: 'search',
      params: {
          search_term: search_input
      }
    })
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;