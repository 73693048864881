<template>
  <ion-page>
      <ion-header mode="ios">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button color="dark" text=""></ion-back-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class=margin-web>
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <img class="media-signup" src="/resources/Logo_HG.png" alt="Happygift logo" style="margin-top: 0;"/>
          </ion-row>

            <div class="line-container">
              <div class="title-sign-up">Registrarme con:</div>
            </div>

          <ion-row>
            <!-- <ion-spinner v-if="isLoading && loginMode === 'google'" name="lines"></ion-spinner> -->
            <ion-col style="display: flex; justify-content: space-around; padding-bottom: 0px;">
              <ion-button mode="ios" fill="clear" class="btn btn-dates btn--borderRadiusXL customDates" @click="signInWithGoogle">
                <img v-if="!session.loginWithSocial.loading" src="../../assets/icons/google-icon.png" alt="google-icon"/>
                <ion-spinner v-if="session.loginWithSocial.loading" name="circles"></ion-spinner>
              </ion-button>
              <ion-button v-if="platform !== 'android' && Capacitor.isNativePlatform()" mode="ios" fill="clear" class="btn btn-dates btn--borderRadiusXL customDates" @click="singWithapple()">
                <ion-icon v-if="!session.loginWithSocial.loading" class="icon-apple" style="font-size: 35px;" slot="icon-only" color="dark"></ion-icon>
                <ion-spinner v-if="session.loginWithSocial.loading" name="circles"></ion-spinner>
              </ion-button>
            </ion-col>
            <ion-col size="12">
              <div class="line-container">
                <div class="line"></div>
                <div class="circle">O</div>
                <div class="line"></div>
              </div>
            </ion-col>

            <ion-col size="12">
              <ion-list class="ion-no-padding">
                <ion-item class='item-field-signup'>
                  <ion-input v-model="firstname" inputmode="text" type="text" label-placement="floating" style="font-size: 16px !important;">
                    <div slot="label" class="font-15" style="color: #b7aaaa;">Nombre</div>
                  </ion-input>
                </ion-item>
                <ion-item class='item-field-signup'>
                  <ion-input v-model="lastname" inputmode="text" type="text" label-placement="floating" style="font-size: 16px !important;">
                    <div slot="label" class="font-15" style="color: #b7aaaa;">Apellido</div>
                  </ion-input>
                </ion-item>
                <ion-item :class="{'item-field-signup': emailMatch == true, 'wrong-input': !emailMatch}" >
                  <ion-input v-model="email" inputmode="text" type="email" label-placement="floating" @input="validateEmail" style="font-size: 16px !important;">
                    <div slot="label" class="font-15" style="color: #b7aaaa;">Email</div>
                  </ion-input>
                </ion-item>
                <ion-text v-if="!emailMatch && email.length > 0" class="font-12 text-error" color="danger">Email inválido</ion-text>

                <ion-item :class="{'item-field-signup': (mobile.length == 0 || mobile.length == 12), 'wrong-input': (mobile.length !== 0 && mobile.length !== 12)}">
                  <ion-input v-model="mobile" placeholder="Formato: +56x x xxx xx xx" class="font-15" label-placement="floating">
                    <div slot="label" class="font-15" style="color: #b7aaaa;">Teléfono</div>
                  </ion-input>
                </ion-item>
                <ion-text v-if="mobile && mobile.length !== 0 && mobile.length !== 12" class="font-12 text-error" color="danger">El teléfono debe tener 12 caracteres</ion-text>

                <ion-item :class="{'item-field-signup': birthDate, 'wrong-input': !birthDate}">
                  <ion-label>Fecha de nacimiento</ion-label>
                    <ion-datetime-button datetime="datetime"></ion-datetime-button>
                  <ion-modal :keep-contents-mounted="true">
                    <ion-datetime id="datetime" presentation="date" v-model=birthDate mode="md" :format-options="formatOptions" :show-default-buttons="true" done-text="Confirmar" cancel-text="Cancelar"></ion-datetime>
                  </ion-modal>
                </ion-item>
                <ion-text v-if="!birthDate" class="font-12 text-error-date" color="medium">Presionar el botón gris con la fecha de hoy para seleccionar</ion-text>

                <ion-item :class="{'item-field-signup': password.length > 7 || password.length == 0, 'wrong-input': password.length < 8 && password.length > 0}">
                    <ion-input v-model="password" :type="passwordFieldType" label-placement="floating" style="font-size: 16px !important;">
                      <div slot="label" class="font-15" style="color: #b7aaaa;">Contraseña</div>
                    </ion-input>
                    <i class="icon" :class="{'icon-eye': passwordFieldType == 'text', 'icon-eyeblock': passwordFieldType == 'password'}" slot="end" @click="switchVisibilityPass"></i>
                </ion-item>
                <ion-text v-if="password.length < 8 && password.length > 0" class="font-12 text-error" color="danger">La contraseña debe tener mínimo 8 caracteres</ion-text>
                <ion-item :class="{'item-field-signup': passwordsMatch || confirmPassword.length == 0, 'wrong-input': !passwordsMatch && confirmPassword.length > 0}">
                  <ion-input v-model="confirmPassword" :type="passwordFieldTypeConfirm" label-placement="floating" @input="handleConfirmPassword" style="font-size: 16px !important;">
                    <div slot="label" class="font-15" style="color: #b7aaaa;">Confirmar contraseña</div>
                  </ion-input>
                  <i class="icon" :class="{'icon-eye': passwordFieldTypeConfirm == 'text', 'icon-eyeblock': passwordFieldTypeConfirm == 'password'}" slot="end" @click="switchVisibilityConfirm"></i>
                </ion-item>
                <ion-text v-if="!passwordsMatch && confirmPassword.length > 0" class="font-12 text-error" color="danger">Las contraseñas no coinciden</ion-text>
              </ion-list>
              <ion-list class="ion-no-padding" lines="none">
                <div class="font-14 signup-checkbox ion-align-items-center" style="display: flex;">
                  <ion-checkbox
                    slot="start"
                    label-placement="end"
                    color="primary"
                    v-model="legalTerms"
                  >
                    Aceptar
                  </ion-checkbox>
                  <a :href="urlConditionTerms" target="_system" style="margin-left: 5px;">Términos y Condiciones</a>
                </div>
                <div class="font-14 signup-checkbox ion-align-items-center" style="display: flex;">
                  <ion-checkbox
                    slot="start"
                    label-placement="end"
                    color="primary"
                    v-model="marketingTerms"
                  >
                    Aceptar
                  </ion-checkbox>
                  <a :href="urlPrivateTerms" target="_system" style="margin-left: 5px;">Política de Privacidad</a>
                </div>
              </ion-list>
              <ion-grid class="ion-no-padding">
                <ion-row class="ion-justify-content-center">
                  <ion-col size="9" style="margin-block: 20px;">
                    <ion-button v-model="submit" class="font-16 login-btn btn" shape="round" expand="block" size="small" mode="ios" @click="signup" :disabled="isButtonDisabled">
                      <ion-label v-if="!session.signup.loading && !session.login.loading">Registrarse</ion-label> 
                      <ion-spinner v-if="session.signup.loading || session.login.loading" name="circles"></ion-spinner>
                    </ion-button>       
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      </ion-content>
  </ion-page>
</template>
  
<script setup lang="ts">
  import { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonContent, 
    IonButtons, 
    IonBackButton, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonButton, 
    IonList, 
    IonItem, 
    IonInput, 
    IonCheckbox, 
    toastController, 
    IonText, 
    IonLabel, 
    IonSpinner, 
    onIonViewDidEnter,
    IonModal,
    IonDatetime,
    IonDatetimeButton,
    IonIcon
  } from '@ionic/vue';
  import { computed, ref, inject, watch } from 'vue';
  import { alertCircleOutline } from 'ionicons/icons'
  import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
  import { Capacitor } from '@capacitor/core';
  //import { useRouter } from 'vue-router';
  //import { AuthService } from '../../services/AuthService';

  const firstname = ref('');
  const lastname = ref('');
  const email = ref('');
  const password = ref('');
  const confirmPassword = ref('');
  const mobile = ref('');
  const birthDate = ref();
  const marketingTerms = ref();
  const legalTerms = ref();
  const passwordFieldType = ref<'text' | 'password'>('password');
  const passwordFieldTypeConfirm = ref<'text' | 'password'>('password');
  const passwordsMatch = ref(true);
  const emailMatch = ref<any>(true);
  const submit = ref(false);
  const platform = ref(null);

  const urlPrivateTerms = import.meta.env.VITE_URL_WEB+'/pages/politica-de-privacidad'
  const urlConditionTerms = import.meta.env.VITE_URL_WEB+'/pages/terminos-y-condiciones-happygift'

  const session: any = inject("$session");
  // const user: any = inject("$user");
  
  const formatOptions = {
    date: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
  };

  const signup = () => {
    session.signup.submit({
      email: email.value,
      password: password.value,
      firstname: firstname.value,
      lastname: lastname.value,
      mobile: mobile.value,
      birth_date: birthDate.value.split('T')[0],
    })
  }

  onIonViewDidEnter(async () => {
    platform.value = Capacitor.getPlatform()
    // console.log('estas en: ', platform.value)
  })

  watch(() => session.signup.error, (error) => {
    if (error) { 
      errorToast(session.signup.error)
    }
  })

  const isButtonDisabled = computed(() => {
    if (mobile.value.length !== 12) return true
    if (!birthDate.value) return true
    return !email.value || !password.value || !marketingTerms.value || !legalTerms.value || !passwordsMatch.value || !emailMatch.value || password.value.length < 8 || !firstname.value || !lastname.value;
  });

  const handleConfirmPassword = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    passwordsMatch.value = confirmPassword.value === password.value;
    return passwordsMatch.value;
  });

  const validateEmail = () => {
    if(email.value.length > 0){
      const isValid = email.value.match(
          /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        );
        emailMatch.value = isValid !== null;
    } else {
      emailMatch.value = true;
    }
  }
    
  const switchVisibilityPass = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
  };

  const switchVisibilityConfirm = () => {
    passwordFieldTypeConfirm.value = passwordFieldTypeConfirm.value === 'password' ? 'text' : 'password';
  };

  const singWithapple = async () => {
    const result = await FirebaseAuthentication.signInWithApple();
    // console.log("user -> ", result);
    session.loginWithSocial.submit({provider: 'apple', user: result.user, token: result.credential.idToken})
    return result.user;
  };

  const signInWithGoogle = async () => {
    const result = await FirebaseAuthentication.signInWithGoogle();
    // console.log("user -> ", result.user, result);
    session.loginWithSocial.submit({provider: 'google',user: result.user, token: result.credential.accessToken})
    return result.user;
  };

  const errorToast = async (message: string) => {
    const toast = await toastController.create({
      message,
      duration: 3500,
      position: 'top',
      icon: alertCircleOutline,
      mode: 'ios'
    });

    await toast.present();
  };
</script>
  
<style scoped>
  ion-toolbar {
    --background: white;
    --color: white;
    --border-color: white;
    --border-width: 0;
  }

  ion-checkbox {
    --size: 20px;
  }
  .signup-checkbox{
    --background: transparent;
    --highlight-height: 0;
    --padding: 0.5rem;
    width: auto;
    padding: 0;
    margin: 15px 15px 15px 20px;
  }

  .media-signup {
    display: block;
    height: auto;
    margin: 50px auto 0px;
    max-width: 70px;
    padding: 0;
    width: 100%;
  }

  .item-field-signup {
    --background: transparent !important;
    --border-color: transparent !important;
    --highlight-height: 0 !important;
    --padding: 0.5rem !important;
    border: solid 1px #b7aaaa !important;
    border-radius: 10px !important;
    margin: 15px 15px 15px 15px !important;
    width: auto !important;
    padding: 0 !important;
    /* max-height: 5.8rem; */
  }
  .signup-title {
    font-size: 20px;
    font-weight:normal;
    margin: 0 0 0 15px;
    padding: 5px 25px 2px;
  }

  .text-error-date {
    --background: transparent;
    --highlight-height: 0;
    --padding: 0.5rem;
    width: auto;
    padding: 0;
    margin: 4px 15px 10px 20px;
    display: block;
  }

  .customDates {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15);
  }

  .btn-dates {
      border-radius: 50% !important; /* Hace el botón completamente redondo */
      height: 65px; /* Ajusta el tamaño del botón según sea necesario */
      width: 65px; /* Ajusta el tamaño del botón según sea necesario */
      padding: 0; /* Elimina cualquier relleno dentro del botón */
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .line-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .line {
    height: 1px;
    background-color: lightgray;
    flex-grow: 1;
  }

  .circle {
    font-size: 16px; /* Ajusta el tamaño según tus necesidades */
    font-weight: bold;
    margin: 0 10px;
    color: black;
    white-space: nowrap;
  }

  .title-sign-up {
    font-size: 20px; /* Ajusta el tamaño según tus necesidades */
    font-weight: bold;
    margin: 0 10px;
    color: black;
    white-space: nowrap;
  }
  @media (min-width: 900px) {
    .margin-web{
      margin-left: 25%;
      margin-right: 25%;
    }
  }
</style>