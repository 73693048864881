<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar v-if="Capacitor.isNativePlatform()" mode="md" slot="bottom">
          <ion-tab-button tab="home" :class="{'active-tab': isActiveTab('home') || isActiveTab('homePublic')}" @click="()=>{router.replace({ name: 'home' });}">
            <ion-icon aria-hidden="true" class="icon icon-home" />
            <!-- <ion-icon aria-hidden="true" class="icon" src="src/assets/icons/Home.svg"/> -->
            Home
          </ion-tab-button>
          <!-- cambiar las rutas -->
          <ion-tab-button 
            tab="catalogue" 
            :class="{'active-tab': isActiveTab(['catalogue'], { occasions: 'Despacho en 3 horas' }) || isActiveTab(['cataloguePublic'], { occasions: 'Despacho en 3 horas' })}" 
            @click="()=>{router.push({name: 'catalogue', query: { occasions: 'Despacho en 3 horas' }})}"> 
            <ion-icon aria-hidden="true" class="icon icon-delivery-express" />
            Express
          </ion-tab-button>

          <ion-tab-button tab="wishes" class="wishes" :class="{'active-tab-wishes': isActiveTab('wishes')}"  @click="()=>{router.push({ name: 'wishes' });}" style="padding: 0px !important;">
            <ion-icon aria-hidden="true" class="icon icon-favorite" style="font-size: 28px; color: #fff; line-height: 1; -webkit-text-stroke: 2px #595959; width: 32px; margin: 0px !important;"/>
            Mis Deseos
          </ion-tab-button>

          <ion-tab-button tab="contacts" :class="{'active-tab': isActiveTab('contacts')}" @click="()=>{router.push({ name: 'contacts' });}">
            <ion-icon aria-hidden="true" class="icon icon-contact"/>
            Contactos
          </ion-tab-button> 

          <ion-tab-button tab="profile" :class="{'active-tab': isActiveTab('profile')}" @click="()=>{router.push({ name: 'profile' });}">
            <ion-icon aria-hidden="true" class="icon icon-user" />
            Perfil
          </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import router from '@/router'
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { useRoute } from 'vue-router';
import { Capacitor } from '@capacitor/core';

const route = useRoute();

const openWhatsApp = () => {
    const whatsappLink = 'https://wa.me/56935909007/?text=Hola%2C%20necesito%20ayuda.';
    InAppBrowser.create(whatsappLink, '_system');
}
const isActiveTab = (tabNames: string | string[], query?: Record<string, string>) => {
  // Convertir tabNames a un array si no lo es
  const names = Array.isArray(tabNames) ? tabNames : [tabNames];
  
  // Verificar si el nombre de la ruta actual está dentro de los nombres permitidos
  if (!names.includes(route.name as string)) {
    return false;
  }

  // Si se proporcionan query parameters, verificar que coincidan
  if (query) {
    for (const key in query) {
      if (route.query[key] !== query[key]) {
        return false;
      }
    }
  }

  // Si todo coincide, retornar true
  return true;
};
</script>

<style scoped>
.icon{
  font-size: 25px;
}
.active-tab ion-icon {
  color: var(--ion-color-primary);
}

.active-tab {
  color: var(--ion-color-primary);
}
ion-icon{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.active-tab-wishes ion-icon {
  color: var(--ion-color-primary) !important;
  -webkit-text-stroke: 2px #a72819 !important;
}

.active-tab-wishes {
  color: var(--ion-color-primary);
}
.wishes{
  --padding-end: 0px;
  --padding-start: 0px;
}
</style>
